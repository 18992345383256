import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'

import * as styles from '../templates/blog-post.module.css'

class About extends React.Component {
  render() {
    const posts = get(this.props, 'data.allContentfulBlogPost.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo
          title={posts[0].title}
          description={posts[0].description.childMarkdownRemark.excerpt}
          image={`http:${posts[0].heroImage.resize.src}`}
        />
        <Hero
          image={posts[0].heroImage?.gatsbyImageData}
          title={posts[0].title}
        />
        <div className={styles.container}>
          <div className={styles.article}>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: posts[0].body?.childMarkdownRemark?.html,
              }}
            />
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: "<p>Instagram: <a href=\"https://www.instagram.com/makersinc.nl/\">@makersinc.nl</a></p>",
              }}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    allContentfulBlogPost(
      filter: { contentful_id: { eq: "1opinsUCFOhITQyu0v46iW" } }
    ) {
      nodes {
        title
        heroImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 2200, quality: 100)
          resize(height: 630, width: 1200) {
            src
          }
        }
        body {
          childMarkdownRemark {
            html
            timeToRead
          }
        }
        description {
          childMarkdownRemark {
            excerpt
          }
        }
      }
    }
  }  
`
